import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
    meta: {
      title: '首页'
    }
  },
  {
    path: '/ewallet',
    name: 'ewallet',
    component: () => import(/* webpackChunkName: "ewallet" */ '../views/ewallet.vue'),
    meta: {
      title: '工银e钱包'
    }
  },
  {
    path: '/epark',
    name: 'epark',
    component: () => import(/* webpackChunkName: "epark" */ '../views/epark.vue'),
    meta: {
      title: '智慧停车'
    }
  },
  {
    path: '/eleInvoce',
    name: 'eleInvoce',
    component: () => import(/* webpackChunkName: "eleInvoce" */ '../views/eleInvoce.vue'),
    meta: {
      title: '财税电票'
    }
  },
  {
    path: '/dataview',
    name: 'dataview',
    component: () => import(/* webpackChunkName: "dataview" */ '../views/dataview.vue'),
    meta: {
      title: '数据可视化'
    }
  },
  {
    path: '/safeope',
    name: 'safeope',
    component: () => import(/* webpackChunkName: "safeope" */ '../views/safeope.vue'),
    meta: {
      title: '安全运维'
    }
  },
  {
    path: '/integration',
    name: 'integration',
    component: () => import(/* webpackChunkName: "safeope" */ '../views/integration.vue'),
    meta: {
      title: '集成项目'
    }
  },
  {
    path: '/cuscenter',
    name: 'cuscenter',
    component: () => import(/* webpackChunkName: "cuscenter" */ '../views/cuscenter.vue'),
    meta: {
      title: '客服中心'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/about.vue'),
    meta: {
      title: '关于我们'
    }
  },
]

const router = new VueRouter({
  routes
})

// router.beforeEach((to, from, next) => {
//   switch (to.name) {
//     case 'index':
//       sessionStorage.setItem("tabIndex", 1);
//       break;
//     case 'ewallet':
//       sessionStorage.setItem("tabIndex", 2);
//       break;
//     case 'epark':
//       sessionStorage.setItem("tabIndex", 3);
//       break;
//     case 'eleInvoce':
//       sessionStorage.setItem("tabIndex", 4);
//       break;
//     case 'dataview':
//       sessionStorage.setItem("tabIndex", 5);
//       break;
//     case 'safeope':
//       sessionStorage.setItem("tabIndex", 6);
//       break;
//     case 'cuscenter':
//       sessionStorage.setItem("tabIndex", 7);
//       break;
//     case 'about':
//       sessionStorage.setItem("tabIndex", 8);
//       break;
//     default:
//       this.$router.push('/')
//       sessionStorage.setItem("tabIndex", 1);
//   }
//   next()
// })

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router


