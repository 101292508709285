<template>
  <div class="home">
    <!-- 轮播图 -->
    <el-carousel
      :height="banHeight + 'px'"
      :interval="4000"
      trigger="click"
      :autoplay="true"
    >
      <el-carousel-item>
        <div class="bannerView">
          <img
            :src="img.banner1"
            alt=""
            class="bannerImg"
            ref="bannerHeight"
            @load="imgLoad"
          />
          <p class="bannerTxt1" v-show="banHeight > 0">不忘初心，砥砺前行</p>
          <p class="bannerTxt1 bannerTxt2" v-show="banHeight > 0">
            用智慧创造无限未来
          </p>
          <p class="bannerTxt3" v-show="banHeight > 0"></p>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <div class="bannerView">
          <img v-lazy="img.banner2" alt="" class="bannerImg" />
          <p class="bannerTxt4">工银e钱包</p>
          <p class="bannerTxt5">
            工银e钱包是中国工商银行向合作方推出的线上零售业务综合服务方案
          </p>
          <p class="bannerTxt6" @click="toPush('ewallet')">查看详情</p>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <div class="bannerView">
          <img v-lazy="img.banner3" alt="" class="bannerImg" />
          <p class="bannerTxt4">电子发票</p>
          <p class="bannerTxt5">
            依托公司大数据、云计算、物联网、区块链等先进技术手段，为企业提供一体化的电子发票服务
          </p>
          <p class="bannerTxt6" @click="toPush('eleInvoce')">查看详情</p>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <div class="bannerView">
          <img v-lazy="img.banner4" alt="" class="bannerImg" />
          <p class="bannerTxt4">智慧停车</p>
          <p class="bannerTxt5">
            打造集中管控智慧停车场，实现减岗增效、无人值守
          </p>
          <p class="bannerTxt6" @click="toPush('epark')">查看详情</p>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="indexBody" v-show="bodyShow">
      <!-- 放假通知 -->
      <div class="notice flex-center-start" v-show="false">
        <img v-lazy="img.fjtz" alt="" class="noticeImg" />
        <img src="@/assets/notice.png" alt="" class="noticeImg mobile" />
        <div class="noticeRight flex-column-start-start">
          <p class="noticeTxt1">2023年春节放假通知</p>
          <p class="noticeTxt2">2023-01-17</p>
          <p class="noticeTxt3">
            放假时间：
            <br />
            2023 年 1 月 19 日（农历腊月二十八）——2023 年 1 月 27 日（农历正月初六）调休放假，共计 9 天。1 月 28 日（农历正月初七）、1月 29 日（农历正月初八）调休上班。
          </p>
          <p class="noticeTxt4" @click="changePopNotice">查看详情></p>
        </div>
      </div>
      <!-- 放假通知 弹窗 -->
      <div class="popNoticeWrap" v-show="popNoticeShow">
        <div class="popNotice">
          <p class="popNoticeTxt1">2023年春节放假通知</p>
          <p class="popNoticeTxt2">2023-01-17</p>
          <p class="popNoticeTxt3">尊敬的会员：您好！</p>
          <p class="popNoticeTxt4">
            根据《国务院办公厅公布2023年放假通知》，结合公司具体情况，为便于各部门及早合理安排工作<br />
            现将2023年春节放假时间通知如下：<br />
            2023 年 1 月 19 日（农历腊月二十八）——2023 年 1 月 27 日（农历正月初六）调休放假，共计 9 天。1 月 28 日（农历正月初七）、1月 29 日（农历正月初八）调休上班。
          </p>
          <p class="popNoticeTxt5">金豆豆互联网有限责任公司</p>
          <p class="popNoticeTxt6">2023年1月17日</p>
          <p class="popNoticeTxt7" @click="changePopNotice">关闭</p>
        </div>
      </div>
      <!-- <div class="specialNoticeBox">
        <img src="@/assets/specialNotice.png" alt="" class="specialNotice" />
        <div class="specialNoticeText">
          <div class="title">关于工银e钱包业务调整通知</div>
          <div class="content">
            因合作协议到期，小金豆平台停止相关金融业务办理。前期通过小金豆平台开立的所有电子账户是工商银行标准电子账户，客户通过“中国工商银行”手机银行应用可继续使用该电子账户办理业务。如有疑问请致电95588咨询或至工商银行网点处理。
          </div>
        </div>
      </div> -->

      <!-- 我们是谁 -->
      <div class="ours">
        <p class="ourTitle">我们是谁</p>
        <p class="ourDes">
          从业7年，专注于客户的需求，专注于市场的变化，创新模式服务于客户的互联网企业
        </p>
        <div class="ourView flex-center-start">
          <div class="ourViewLeft">
            <video width="748" height="420" controls loop ref="videoref">
              <source src="@/assets/video.mp4" type="video/mp4" />
            </video>
            <img
              class="playIcon"
              src="@/assets/playIcon.png"
              alt=""
              @click="toPlay"
              v-show="playIconShow"
            />
          </div>
          <div class="ourViewRight flex-column-start-start">
            <p class="ourViewRightTxt1">公司介绍</p>
            <p class="ourViewRightTxt2">
              金豆豆互联网有限责任公司（JDD
              InternetCo.,Ltd.）成立于2015年，由国家工商行政管理总局核准，注册资本壹亿元整。总公司位于河南省郑州市金科智汇谷，业务覆盖河南、海南、深圳、湖南、湖北，主要经营互联网信息服务、电信增值业务、软件开发与服务、电子商务、企业管理咨询；科技中介服务；物联网应用服务；物业管理；停车场服务、电子发票服务。
            </p>
            <p class="ourViewRightTxt3" v-if="isShow" @click="toqq">
              咨询业务详情
            </p>
          </div>
        </div>
        <div class="mobileOurView">
          <div class="text">
            金豆豆互联网有限责任公司（JDD
            InternetCo.,Ltd.）成立于2015年，由国家工商行政管理总局核准，注册资本壹亿元整。总公司位于河南省郑州市金科智汇谷，业务覆盖河南、海南、深圳、湖南、湖北，主要经营互联网信息服务、电信增值业务、软件开发与服务、电子商务、企业管理咨询；科技中介服务；物联网应用服务；物业管理；停车场服务、电子发票服务。
          </div>
          <video controls loop ref="mebelVideoref">
            <source src="@/assets/video.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
      <!-- 为什么选择我们 -->
      <div class="ours">
        <p class="ourTitle">为什么选择我们</p>
        <p class="ourDes">
          专业团队，全程策划，诚信服务，精至完美，诚 • 博客户 谋 • 定市场 策 •
          动未来
        </p>
        <div class="choseView flex-center-between">
          <div class="choseItem flex-column-center-start">
            <img v-lazy="img.chose11" alt="" class="choseImg" />
            <p class="choseTxt1">雄厚的研发实力</p>
            <p class="choseTxt2">
              公司拥有一批高素质的管理人员和技术力量雄厚的设计开发团队。
            </p>
          </div>
          <div class="choseItem flex-column-center-start">
            <img v-lazy="img.chose22" alt="" class="choseImg" />
            <p class="choseTxt1">完善的运营策划</p>
            <p class="choseTxt2">
              向客户提供专业的软件开发服务、应用技术服务、互联网顾问咨询及完整的信息化全方位解决方案。
            </p>
          </div>
          <div class="choseItem flex-column-center-start">
            <img v-lazy="img.chose33" alt="" class="choseImg" />
            <p class="choseTxt1">高级别安全保障</p>
            <p class="choseTxt2">
              365天不间断稳定运行保障体系，服务器群分布在多个IDC机房，各个机房之间线路实现网络实时监控等。
            </p>
          </div>
          <div class="choseItem flex-column-center-start">
            <img v-lazy="img.chose44" alt="" class="choseImg" />
            <p class="choseTxt1">精致的服务能力</p>
            <p class="choseTxt2">
              不断的为客户提供高质量、高效率、高安全性的7x24小时专业运维保证。
            </p>
          </div>
        </div>
      </div>
      <!-- 我们提供的服务 -->
      <div class="ours servers">
        <p class="ourTitle">我们提供的服务</p>
        <p class="ourDes">
          致力于软件开发/服务，数据分析，资源整合，整体方案制定
        </p>
        <div class="serverView flex-start-start">
          <div class="serLeft">
            <div class="imgViews">
              <img v-lazy="img.h12" alt="" class="serLeft1 hBg" />
              <div class="imgTxtExtra">
                <div class="imgTxt">
                  <div>
                    软件开发/服务
                    <br />
                    高端定制
                  </div>
                </div>
                <div class="imgTxtExtraShow imgTxtExtraTxt1">
                  软件开发/服务
                  <br />
                  高端定制
                </div>
                <div class="imgTxtExtraShow imgTxtExtraTxt2">
                  专注于信息化平台、应用的研究,丰富的开发经验和团队建设,成熟和稳定的定制开发解决方案
                </div>
                <div
                  class="imgTxtExtraShow imgTxtExtraTxt3"
                  @click="toEwallet(3)"
                >
                  查看详情
                </div>
              </div>
              <img v-lazy="img.ser1" alt="" class="serLeft1" />
            </div>
            <div class="imgViews">
              <img v-lazy="img.h11" alt="" class="serLeft2 hBg" />
              <div class="imgTxtExtra">
                <p class="imgTxt">活体验证</p>
                <div class="imgTxtExtraShow imgTxtExtraTxt1">活体验证</div>
                <div class="imgTxtExtraShow imgTxtExtraTxt2">
                  提供人脸识别及活体检测，用户证件验证安全高效，多重数据交叉验证
                </div>
                <div
                  class="imgTxtExtraShow imgTxtExtraTxt3"
                  @click="toEwallet(4)"
                >
                  查看详情
                </div>
              </div>
              <img v-lazy="img.ser2" alt="" class="serLeft2" />
            </div>
          </div>
          <div class="serRight">
            <div class="imgViews">
              <img v-lazy="img.h11" alt="" class="serLeft2 hBg" />
              <div class="imgTxtExtra">
                <p class="imgTxt">运营策划</p>
                <div class="imgTxtExtraShow imgTxtExtraTxt1">运营策划</div>
                <div class="imgTxtExtraShow imgTxtExtraTxt2">
                  提升品牌好感度和客户忠诚度
                </div>
                <div
                  class="imgTxtExtraShow imgTxtExtraTxt3"
                  @click="toEwallet(5)"
                >
                  查看详情
                </div>
              </div>
              <img v-lazy="img.ser3" alt="" class="serLeft2" />
            </div>
            <div class="imgViews">
              <img v-lazy="img.h21" alt="" class="serLeft3 hBg" />
              <div class="imgTxtExtra">
                <p class="imgTxt">数据可视化</p>
                <div class="imgTxtExtraShow imgTxtExtraTxt1">数据可视化</div>
                <div class="imgTxtExtraShow imgTxtExtraTxt2">
                  深耕用户行为分析领域，专注用户行为数据分析，探索永无止境
                </div>
                <div
                  class="imgTxtExtraShow imgTxtExtraTxt3"
                  @click="toPush('dataview')"
                >
                  查看详情
                </div>
              </div>
              <img v-lazy="img.ser4" alt="" class="serLeft3" />
            </div>
            <div class="imgViews">
              <img v-lazy="img.h21" alt="" class="serLeft3 hBg" />
              <div class="imgTxtExtra">
                <p class="imgTxt">智慧停车</p>
                <div class="imgTxtExtraShow imgTxtExtraTxt1">智慧停车</div>
                <div class="imgTxtExtraShow imgTxtExtraTxt2">
                  打造集中管控智慧停车场，实现移动支付、电子发票、远程开闸基础业务，监控和分析异常开闸情况，实现减岗增效、无人值守。
                </div>
                <div
                  class="imgTxtExtraShow imgTxtExtraTxt3"
                  @click="toPush('epark')"
                >
                  查看详情
                </div>
              </div>
              <img v-lazy="img.ser5" alt="" class="serLeft3" />
            </div>
            <div class="imgViews">
              <img v-lazy="img.h11" alt="" class="serLeft2 hBg" />
              <div class="imgTxtExtra">
                <p class="imgTxt">安全运维</p>
                <div class="imgTxtExtraShow imgTxtExtraTxt1">安全运维</div>
                <div class="imgTxtExtraShow imgTxtExtraTxt2">
                  提高业务连续性，降低IT运营成本，构建安全运营体系
                </div>
                <div
                  class="imgTxtExtraShow imgTxtExtraTxt3"
                  @click="toPush('safeope')"
                >
                  查看详情
                </div>
              </div>
              <img v-lazy="img.ser6" alt="" class="serLeft2" />
            </div>
            <div class="imgViews">
              <img v-lazy="img.h11" alt="" class="serLeft2 hBg" />
              <div class="imgTxtExtra">
                <p class="imgTxt">客服中心</p>
                <div class="imgTxtExtraShow imgTxtExtraTxt1">客服中心</div>
                <div class="imgTxtExtraShow imgTxtExtraTxt2">
                  提高客户满意度，统一客服管理平台，提升客服工作效率
                </div>
                <div
                  class="imgTxtExtraShow imgTxtExtraTxt3"
                  @click="toPush('cuscenter')"
                >
                  查看详情
                </div>
              </div>
              <img v-lazy="img.ser7" alt="" class="serLeft2" />
            </div>
            <div class="imgViews">
              <img v-lazy="img.h21" alt="" class="serLeft3 hBg" />
              <div class="imgTxtExtra">
                <p class="imgTxt">财税电票</p>
                <div class="imgTxtExtraShow imgTxtExtraTxt1">财税电票</div>
                <div class="imgTxtExtraShow imgTxtExtraTxt2">
                  依托公司大数据、云计算、物联网、区块链等先进技术手段，为企业提供一体化的电子发票服务
                </div>
                <div
                  class="imgTxtExtraShow imgTxtExtraTxt3"
                  @click="toPush('eleInvoce')"
                >
                  查看详情
                </div>
              </div>
              <img v-lazy="img.ser8" alt="" class="serLeft3" />
            </div>
          </div>
        </div>
        <div class="mobileServerView">
          <div class="imgViewItem">
            <img v-lazy="img.ser1" alt="" class="serLeft1 hBg" />
            <div class="text flex-column-center-center">
              <div>
                软件开发/服务 <br />
                高端定制
              </div>
            </div>
          </div>
          <div class="imgViewItem">
            <img v-lazy="img.ser3" alt="" class="serLeft2 hBg" />
            <div class="text flex-column-center-center">
              <div>运营策划</div>
            </div>
          </div>
          <div class="imgViewItem">
            <img v-lazy="img.ser6" alt="" class="serLeft2 hBg" />
            <div class="text flex-column-center-center">
              <div>安全运维</div>
            </div>
          </div>
          <div class="imgViewItem">
            <img v-lazy="img.ser5" alt="" class="serLeft3 hBg" />
            <div class="text flex-column-center-center">
              <div>智慧停车</div>
            </div>
          </div>
          <div class="imgViewItem">
            <img v-lazy="img.ser2" alt="" class="serLeft2 hBg" />
            <div class="text flex-column-center-center">
              <div>活体验证</div>
            </div>
          </div>
          <div class="imgViewItem">
            <img v-lazy="img.ser7" alt="" class="serLeft2 hBg" />
            <div class="text flex-column-center-center">
              <div>客服中心</div>
            </div>
          </div>
          <div class="imgViewItem">
            <img v-lazy="img.ser9" alt="" class="serLeft3 hBg" />
            <div class="text flex-column-center-center">
              <div>数据可视化</div>
            </div>
          </div>
          <div class="imgViewItem">
            <img v-lazy="img.ser8" alt="" class="serLeft3 hBg" />
            <div class="text flex-column-center-center">
              <div>财税电票</div>
            </div>
          </div>
        </div>
        <p class="ourViewRightTxt3" v-if="isShow" @click="toqq">咨询业务详情</p>
      </div>
      <!-- 软件资质 -->
      <div class="ours">
        <p class="ourTitle">软件资质</p>
        <p class="ourDes">拥有IT行业完善的体系资质</p>
        <div class="zzView">
          <img v-lazy="img.zz6" alt="" class="zzImg img6" />
          <img v-lazy="img.zz13" alt="" class="zzImg img13" />
          <img v-lazy="img.zz5" alt="" class="zzImg img5" />
          <img v-lazy="img.zz14" alt="" class="zzImg img6" />
          <img v-lazy="img.zz15" alt="" class="zzImg img13" />
          <img v-lazy="img.zz16" alt="" class="zzImg img5" />
          <img v-lazy="img.zz7" alt="" class="zzImg img7" />
          <img v-lazy="img.zz8" alt="" class="zzImg img8" />
          <img v-lazy="img.zz9" alt="" class="zzImg img9" />
          <img v-lazy="img.zz11" alt="" class="zzImg img1" />
          <img v-lazy="img.zz12" alt="" class="zzImg img1" />
          <img v-lazy="img.zz1" alt="" class="zzImg img1" />
          <img v-lazy="img.zz2" alt="" class="zzImg img2" />
          <img v-lazy="img.zz4" alt="" class="zzImg img4" />
          <img v-lazy="img.zz3" alt="" class="zzImg img3" />
          <img v-lazy="img.zz10" alt="" class="zzImg img4" />
          <img v-lazy="img.zz4" alt="" class="zzImg img10" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import holidayImg from '@/assets/holiday.png'
export default {
  name: "index",
  data() {
    return {
      banHeight: "",
      playIconShow: true,
      popNoticeShow: false,
      bodyShow: false,
      isShow: true,
      img: {
        banner1:
          "./images/banner1.png",
        banner2:
          "./images/banner2.png",
        banner3:
          "./images/banner3.png",
        banner4:
          "./images/banner4.png",
        fjtz: holidayImg,
        chose11:
          "./images/chose11.png",
        chose22:
          "./images/chose22.png",
        chose33:
          "./images/chose33.png",
        chose44:
          "./images/chose44.png",
        ser1: "./images/ser1.png",
        ser2: "./images/ser2.png",
        ser3: "./images/ser3.png",
        ser4: "./images/ser4.png",
        ser5: "./images/ser5.png",
        ser6: "./images/ser6.png",
        ser7: "./images/ser7.png",
        ser8: "./images/ser8.png",
        ser9: "./images/ser9.png",
        zz1: "./images/zz1.png",
        zz2: "./images/zz2.png",
        zz3: "./images/zz3.png",
        zz4: "./images/zz4.png",
        zz5: "./images/zz5.png",
        zz6: "./images/zz6.png",
        zz7: "./images/zz7.jpg",
        zz8: "./images/zz8.png",
        zz9: "./images/zz9.png",
        zz10: "./images/zz10.png",
        zz11: "./images/zz11.png",
        zz12: "./images/zz12.png",
        zz13: "./images/zz13.png",
        zz14: "./images/zz14.png",
        zz15: "./images/zz15.png",
        zz16: "./images/zz16.png",
        h12: "./images/h12.png",
        h21: "./images/h21.png",
        h11: "./images/h11.png",
      },
    };
  },
  mounted() {
    window.addEventListener("resize", this.imgLoad, false);
    var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
    if (isMac) {
      this.isShow = false;
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.imgLoad, false);
  },
  methods: {
    toqq() {
      if (Math.random() > 0.5) {
        window.open("tencent://message/?uin=3607907547");
      } else {
        window.open("tencent://message/?uin=3073534076");
      }
    },
    toEwallet(e) {
      this.$router.push({
        path: "/ewallet",
        query: {
          floor: e,
        },
      });
    },
    toPush(url) {
      console.log(url);
      this.$router.push(url);
    },
    imgLoad() {
      this.$nextTick(() => {
        let ref = this.$refs["bannerHeight"];
        if (!!ref.offsetHeight) {
          this.banHeight = ref.offsetHeight;
        }
        this.bodyShow = true;
      });
    },
    toPlay() {
      this.playIconShow = !this.playIconShow;
      this.$refs["videoref"].play();
    },
    changePopNotice() {
      this.popNoticeShow = !this.popNoticeShow;
    },
  },
};
</script>

<style scoped lang="scss">
.indexBody {
  margin: 0 auto;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.notice {
  width: 1200px;
  height: 203px;
  background: #ffffff;
  border-radius: 1px;
  border: 1px solid #e2e3e4;
  box-sizing: border-box;
  padding: 20px;
  margin: 62px auto 0 auto;
  .noticeImg {
    width: 290px;
    height: 162px;
    margin-right: 20px;
  }
  .noticeRight {
    height: 100%;
    .noticeTxt1 {
      font-size: 30px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 10px;
    }
    .noticeTxt2 {
      font-size: 18px;
      color: #666666;
      margin-bottom: 10px;
    }
    .noticeTxt3 {
      margin-bottom: 10px;
      font-size: 18px;
      color: #999999;
      line-height: 20px;
      max-height: 40px;
      display: -webkit-box;
      /*! autoprefixer: off */
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .noticeTxt4 {
      font-size: 12px;
      color: #ff8800;
      cursor: pointer;
    }
  }
}
.ours {
  width: 1200px;
  margin: 100px auto;
  text-align: center;
  .ourTitle {
    font-size: 30px;
    font-weight: bold;
    color: #333333;
    line-height: 40px;
  }
  .ourDes {
    font-size: 18px;
    color: #999999;
    line-height: 24px;
    margin-bottom: 40px;
  }
  .ourView {
    width: 1200px;
    height: 480px;
    background: #ffffff;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 20px;
    .ourViewLeft {
      width: 748px;
      height: 420px;
      position: relative;
      margin-right: 20px;
      .playIcon {
        width: 82px;
        height: 82px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .ourViewRight {
      width: 380px;
      height: 100%;
      .ourViewRightTxt1 {
        font-size: 24px;
        color: #333333;
        line-height: 31px;
        margin: 10px 0;
      }
      .ourViewRightTxt2 {
        font-size: 16px;
        color: #666666;
        line-height: 30px;
        text-align: left;
        margin-bottom: 20px;
      }
      .ourViewRightTxt3 {
        cursor: pointer;
        width: 190px;
        height: 46px;
        border-radius: 8px;
        border: 1px solid #ff8800;
        font-size: 20px;
        color: #ff8800;
        line-height: 46px;
      }
    }
  }
  .mobelOurView {
    display: none;
  }
}
.ourViewRightTxt3 {
  margin: 60px auto 0 auto;
  cursor: pointer;
  width: 190px;
  height: 46px;
  border-radius: 8px;
  border: 1px solid #ff8800;
  font-size: 20px;
  color: #ff8800;
  line-height: 46px;
}
.choseView {
  width: 1200px;
  .choseItem {
    width: 285px;
    height: 380px;
    background: #ffffff;
    border-radius: 8px;
    transition: all 0.6s;
    &:hover {
      transform: scale(1.1);
    }
    .choseImg {
      width: 117px;
      height: 112px;
      margin: 40px 0 28px 0;
    }
    .choseTxt1 {
      font-size: 22px;
      font-weight: bold;
      color: #333333;
      line-height: 29px;
    }
    .choseTxt2 {
      width: 215px;
      height: 90px;
      font-size: 16px;
      color: #999999;
      line-height: 30px;
    }
  }
}
.servers {
  width: 100%;
  min-height: 400px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 127px 0;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    width: 0;
    height: 0;
    margin-left: -60px;
    border-left: 60px solid transparent;
    border-right: 60px solid transparent;
    border-top: 20px solid #f5f7fa;
  }
  &:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -20px;
    width: 0;
    height: 0;
    margin-left: -60px;
    border-left: 60px solid transparent;
    border-right: 60px solid transparent;
    border-top: 20px solid #fff;
  }
  .serverView {
    width: 1200px;
    margin: 0 auto;
    .imgViews {
      cursor: pointer;
      position: relative;
      &:hover .imgTxt {
        opacity: 0;
      }
      &:hover .imgTxtExtraShow {
        opacity: 1;
      }
      &:hover .hBg {
        display: block !important;
        opacity: 0.8;
      }
      img {
        display: block;
      }
      .imgTxt {
        width: 100%;
        text-align: left;
        position: absolute;
        top: 46%;
        left: 0%;
        font-size: 32px;
        font-weight: bold;
        color: #ffffff;
        line-height: 42px;
        display: flex;
        justify-content: center;
      }
    }
    .serLeft1 {
      width: 285px;
      height: 590px;
    }
    .serLeft2 {
      width: 285px;
      height: 285px;
    }
    .serLeft3 {
      width: 590px;
      height: 285px;
    }
    .serLeft {
      width: 285px;
      height: 895px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .serRight {
      width: 895px;
      height: 895px;
      margin-left: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: space-between;
    }
    .hBg {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }
    .imgTxtExtra {
      position: absolute;
      color: #fff;
      width: 80%;
      left: 50%;
      top: 44%;
      margin-left: -40%;
      margin-top: -90px;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;

      .imgTxtExtraShow {
        opacity: 0;
      }
      .imgTxtExtraTxt1 {
        font-size: 32px;
        font-weight: bold;
        color: #ffffff;
        line-height: 42px;
      }
      .imgTxtExtraTxt2 {
        width: 90%;
        font-size: 18px;
        color: #ffffff;
        line-height: 30px;
        margin: 6px 0 20px 0;
      }
      .imgTxtExtraTxt3 {
        width: 118px;
        height: 42px;
        border: 1px solid #ffffff;
        font-size: 18px;
        color: #ffffff;
        line-height: 42px;
        cursor: pointer;
      }
    }
  }
}
.zzView {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 10px;
  .zzImg {
    width: 570px;
    margin: 10px;
    display: block;
  }
  .img1,
  .img2,
  .img4 {
    width: 275px;
  }
  .img3,
  .img10 {
    display: none;
  }
  .img5,.img6,.img13 {
    height: 268px;
    width:auto;
  }
}
.popNoticeWrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10000;
}
.popNotice {
  width: 1200px;
  min-height: 362px;
  background: #ffffff;
  box-shadow: 0px 10px 24px 0px rgba(153, 153, 153, 0.6);
  border-radius: 8px;
  position: fixed;
  top: 14%;
  left: 50%;
  margin-left: -600px;
  z-index: 2;
  box-sizing: border-box;
  padding: 60px 50px;
  .popNoticeTxt1 {
    font-size: 28px;
    color: #333333;
    line-height: 37px;
    margin-bottom: 11px;
  }
  .popNoticeTxt2 {
    font-size: 16px;
    color: #999999;
    line-height: 30px;
    margin-bottom: 28px;
  }
  .popNoticeTxt3 {
    font-size: 20px;
    color: #666666;
    line-height: 30px;
    margin-bottom: 30px;
  }
  .popNoticeTxt4 {
    font-size: 20px;
    color: #666666;
    line-height: 50px;
    text-indent: 40px;
  }
  .popNoticeTxt5 {
    font-size: 20px;
    color: #666666;
    line-height: 30px;
    text-align: right;
    margin-top: 30px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .popNoticeTxt6 {
    font-size: 20px;
    color: #666666;
    line-height: 30px;
    margin-right: 30px;
    text-align: right;
  }
  .popNoticeTxt7 {
    text-align: right;
    margin-top: 30px;
    font-size: 20px;
    color: #ff8800;
    line-height: 0.65rem;
    cursor: pointer;
  }
}
.bannerView {
  position: relative;
  width: 100%;
  height: 100%;
  .bannerImg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .bannerTxt1 {
    font-size: 40px;
    font-weight: bold;
    color: #333333;
    line-height: 52px;
    position: absolute;
    top: 38%;
    left: 18%;
  }
  .bannerTxt2 {
    top: 52%;
    left: 24%;
  }
  .bannerTxt3 {
    width: 118px;
    height: 7px;
    background: #ff8800;
    position: absolute;
    top: 68%;
    left: 21%;
  }
  .bannerTxt4 {
    font-size: 40px;
    font-weight: bold;
    color: #333333;
    line-height: 52px;
    position: absolute;
    top: 30%;
    left: 19%;
  }
  .bannerTxt5 {
    font-weight: normal;
    font-size: 22px;
    color: #666666;
    line-height: 31px;
    position: absolute;
    top: 44%;
    left: 19%;
  }
  .bannerTxt6 {
    text-align: center;
    width: 118px;
    height: 42px;
    background: #ff8800;
    border-radius: 4px;
    font-size: 18px;
    color: #ffffff;
    line-height: 42px;
    position: absolute;
    top: 60%;
    left: 19%;
    cursor: pointer;
  }
}
.mobileOurView,
.mobileServerView,
.mobile {
  display: none;
}
.specialNoticeBox{
  max-width: 1200px;
  margin: 62px auto 0 auto;
  position: relative;
}
.specialNotice{
  width:100%;
  display: block;
}
.specialNoticeText{
  position: absolute;
  width:100%;
  height:100%;
  top:0;
  left:0;
  bottom:0;
  box-sizing: border-box;
  padding:0 6%;
  font-size: 20px;
  .title{
    font-size:30px;
    color:#fff;
    text-align: center;
    height:32%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content{
    display: flex;
    align-items: center;
    justify-content: center;
    height:57%;
  }
}
@media screen and (max-width: 1200px) {
  .indexBody {
    padding: 0 30px;
  }
  .notice {
    width: auto;
    height: 70px;
    margin-top: 30px;
    justify-content: start;
    .noticeImg {
      display: none;
    }
    .mobile {
      display: block;
      width: 28px;
      height: 27px;
    }
    .noticeRight {
      height: auto;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      flex-grow: 1;
      .noticeTxt1 {
        font-weight: normal;
        margin-bottom: 0;
      }
      .noticeTxt2,
      .noticeTxt3 {
        display: none;
      }
      .noticeTxt4 {
        font-size: 20px;
      }
    }
  }
  .ours {
    width: auto;
    .ourTitle {
      font-weight: normal;
      font-size: 36px;
    }
    .ourDes {
      display: none;
    }
    .ourView {
      display: none;
    }
    .mobileOurView {
      display: block;
      video {
        width: 100%;
      }
      .text {
        max-height: 1000px;
        color: #666666;
        line-height: 35px;
        margin: 0.65rem 0;
        text-align: left;
        font-size: 26px;
      }
    }
  }
  .choseView {
    display: grid;
    width: auto;
    grid-template-columns: 49% 49%;
    row-gap: 2%;
    margin-top: 30px;
    .choseItem {
      width: auto;
      padding: 30px;
      height: auto;
      .choseImg {
        width: 50%;
        height: auto;
        margin: 0;
      }
      .choseTxt1 {
        font-size: 30px;
        width: auto;
        font-weight: normal;
      }
      .choseTxt2 {
        font-size: 20px;
        width: auto;
        text-align: left;
        margin-top: 12px;
      }
    }
  }
  .serverView {
    display: none;
  }
  .mobileServerView {
    display: grid;
    grid-template-columns: repeat(3, 32.5%);
    column-gap: 1.25%;
    .imgViewItem {
      position: relative;
      margin-top: 30px;
      img {
        width: 100%;
        display: block;
      }
      .text {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        font-size: 30px;
        color: #fff;
        text-align: left;
      }
    }
    .imgViewItem:nth-child(1) {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 3;
    }
    .imgViewItem:nth-child(4) {
      grid-column-start: 2;
      grid-column-end: 4;
      grid-row-start: 2;
      grid-row-end: 3;
    }
    .imgViewItem:nth-child(7) {
      grid-column-start: 3;
      grid-column-end: 4;
      grid-row-start: 3;
      grid-row-end: 5;
    }
    .imgViewItem:nth-child(8) {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 4;
      grid-row-end: 5;
    }
  }
  .ourViewRightTxt3 {
    display: none;
  }
  .zzView {
    width: auto;
    margin-top: 30px;
    justify-content: center;
    .zzImg {
      width: 47%;
    }
    .img3,
    .img10 {
      display: block;
    }
    .img4 {
      display: none;
    }
    .img5,
    .img6,
    .img13 {
      width: 80%;
      height: auto;
    }
    .img7,
    .img8,
    .img9 {
      width: 100%;
    }
  }
  .popNotice {
    width: 90%;
    margin-left: -45%;
    top: 20%;
  }
  .bannerView {
    .bannerTxt1 {
      top: 28%;
      left: 8%;
    }
    .bannerTxt2 {
      top: 52%;
      left: 15%;
    }
    .bannerTxt3 {
      top: 74%;
      left: 10%;
    }
    .bannerTxt4,
    .bannerTxt5,
    .bannerTxt6 {
      left: 10%;
    }
    .bannerTxt4 {
      top: 20%;
    }
    .bannerTxt6 {
      top: 70%;
    }
  }
}
</style>
