import Vue from 'vue'
import { Row, Col, Carousel, CarouselItem } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import VueLazyload from 'vue-lazyload'
import BigImg from '@/components/BigImg'
import '@/style/common.scss'
Vue.use(VueLazyload, {
  preload: 1.1, // 加载区域相对于可视区域的比例，即加载区域 = 容器高度(可视区域) * preload
  loading: require('@/assets/load.png'), // 图片加载过程中显示
});
Vue.component(BigImg.name, BigImg);

Vue.use(Row)
Vue.use(Col)
Vue.use(Carousel)
Vue.use(CarouselItem)

Vue.config.productionTip = false


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
