<template>
  <div id="app">
    <tab></tab>
    <router-view />
    <foot></foot>
  </div>
</template>

<script>
import tab from "@/components/tab.vue";
import foot from "@/components/foot.vue";
export default {
  data() {
    return {};
  },
  components: {
    tab,
    foot,
  },
};
</script>


<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
  font-size: calc(40 * 100vw / 1920);
}
body {
  overflow-y: scroll;
}
.rightView .floor-item:first-child .floor-item-box {
  .itemTit {
    margin-top: 0;
  }
}
</style>
