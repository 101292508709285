<template>
  <div class="bigImgViewr">
    <div class="pc">
      <img
        v-lazy="src"
        alt=""
        srcset=""
        @click="isShowBig = true"
        class="noramlImg"
      />
      <div class="bigImgModal" @click="isShowBig = false" v-show="isShowBig">
        <img :src="src" alt="" class="bigImg" />
      </div>
    </div>
    <img v-lazy="src" alt="" srcset="" class="noramlImg mobile" />
  </div>
</template>

<script>
export default {
  name: "BigImg",
  data() {
    return {
      isShowBig: false,
    };
  },
  props: ["src"],
};
</script>

<style scoped>
.bigImgModal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.noramlImg {
  cursor: zoom-in;
}
img {
  max-width: 100%;
}
.bigImg {
  max-width: 90%;
  max-height: 90%;
  cursor: zoom-out;
}
.mobile {
  display: none;
}
@media screen and(max-width:1200) {
  .pc {
    display: none;
  }
  .mobile {
    display: block;
  }
}
</style>>
