<template>
  <el-row>
    <el-col :span="24">
      <div class="footView">
        <div class="flex-center-center contact">
          <div class="footLeft">
            <div class="txt1">联系我们</div>
            <div class="txt2">客服：<span class="txt21">95157</span></div>
            <div class="txt3">邮箱：jindoudou@jdd966.com</div>
            <div class="txt3 flex-start-start">
              <div style="flex-shrink: 0;">总部：</div><div>郑州市金水区东三环鸿宝路金科智汇谷七号楼三层</div>
            </div>
            <div class="txt3">分部：海南、深圳、湖南、湖北</div>
          </div>
          <div class="footRight">
            <div class="txt1">关注我们</div>
            <div class="ewmBox flex-center-start">
              <div class="ewmView flex-column-center-center">
                <img v-lazy="img.xjdfw" alt="" class="ewm" />
                <div class="ewmTxt">小金豆服务公众号</div>
              </div>
              <div class="ewmView flex-column-center-center">
                <img v-lazy="img.gytc" alt="" class="ewm" />
                <div class="ewmTxt">金豆停车公众号</div>
              </div>
              <div class="ewmView flex-column-center-center">
                <img v-lazy="img.xcx" alt="" class="ewm" />
                <div class="ewmTxt">金豆豆服务小程序</div>
              </div>
              <div class="ewmView flex-column-center-center">
                <img v-lazy="img.jddp" alt="" class="ewm" />
                <div class="ewmTxt">金豆电票公众号</div>
              </div>
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div class="footTxt flex-center-center">
          <div>
            <span>Copyright©2016-2023 金豆豆互联网有限责任公司 All rights reserved.</span>
            <span>增值电信业务经营许可证：B1.B2-20221929. </span>
            工信部备案号:
            <a class="beiana" href="https://beian.miit.gov.cn/"
              >豫ICP备16021989号-1.</a>
          </div>
          <div class="beianBox flex-center-center">
            <img v-lazy="img.jx" alt="" class="jx" />
            <span>
              <a
                class="beiana"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41010502005187"
                >豫公网安备 41010502005187号</a
              >
            </span>
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "foot",
  props: {},
  data() {
    return {
      img: {
        xjdfw:
          "./images/xjdfw.jpg",
        gytc: "./images/gytc.jpg",
        xcx: "./images/xcx.jpg",
        jddp: "./images/jddp.jpg",
        jx: "./images/jx.png",
      },
    };
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.footView {
  width: 100%;
  box-sizing: border-box;
  padding: 40px 0;
  background-color: #263345;
  border-radius: 1px;
  .txt1 {
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .txt2 {
    font-size: 14px;
    color: #ffffff;
    line-height: 19px;
    margin-bottom: 10px;
  }
  .txt21 {
    font-size: 20px;
  }
  .txt3 {
    font-size: 14px;
    color: #ffffff;
    line-height: 19px;
    margin-bottom: 10px;
  }
  .footRight {
    margin-left: 10%;
  }
  .ewmView {
    font-size: 14px;
    color: #ffffff;
    line-height: 19px;
    margin-right: 40px;
  }
  .ewm {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }
}
.line {
  width: 100%;
  height: 1px;
  background-color: #666;
  margin-top: 30px;
}
.footTxt {
  font-size: 12px;
  color: #999999;
  line-height: 16px;
  text-align: center;
  box-sizing: border-box;
  padding-top: 10px;
}
.jx {
  width: 18px;
  height: 18px;
}
.beiana {
  text-decoration: none;
  cursor: pointer;
}
.beiana:link {
  color: #999;
}

.beiana:visited {
  color: #999;
}
@media screen and (max-width: 1200px) {
  .footView {
    height:auto;
    .txt1{
      font-size:30px;
    }
    .txt2,.txt3 {
      font-size: 26px;
      color:#999;
      line-height: 36px;
    }
    .ewmView {
      display: none;
    }
    .ewmView:first-child {
      display: block;
    }
    .contact{
      padding:0 24px;
      .footLeft{
        flex-shrink: 1;
      }
    }
    .ewm{
      width:200px;
      height:200px;
    }
  }
  .footTxt {
    display: block;
    font-size:22px;
    line-height: 30px;
    span{
      display: block;
    }
  }
}
</style>
