<template>
  <el-row :style="{ height: topViewHeight + 'px' }">
    <el-col :span="24">
      <div class="topView flex-center-between">
        <img v-lazy="img.logo" alt="金豆豆" class="logo" @click="toPush('/')" />
        <div class="tabs flex-center-start">
          <router-link :to="item.path" exact :class="[{ act: $router.currentRoute.path === item.path }, 'tabItem']" v-for="item in $router.options.routes" :key="item.path">
            {{item.meta.title}}
          </router-link>
        </div>
        <div
          class="tabs mobile"
          v-show="isShowTabs"
          :style="{ top: topViewHeight + 'px' }"
        >
          <div @click="toTab(item)" :class="[{ act: $router.currentRoute.path === item.path }, 'tabItem']" v-for="item in $router.getRoutes()" :key="item.path">
            {{item.meta.title}}
          </div>
        </div>
        <div class="menu" @click="menuClick" v-show="!isShowTabs"></div>
        <div class="close" @click="menuClick" v-show="isShowTabs"></div>
      </div>
    </el-col>
    <!-- 客服 -->
    <div class="kfBig">
      <div
        class="toTop flex-center-center"
        @click="backTop"
        :class="{ show: isShowToTop }"
      >
        <img src="@/assets/toTop.png" alt="" class="toTopImg" />
      </div>
      <div class="kfFix" @click="toqq">
        <img v-lazy="img.fkFix" alt="" class="fkFixImg" @click="toqq" />
        <p class="fkTxt">咨询客服</p>
        <div class="kfDes">
          <p class="kfTxt1">客服热线</p>
          <p class="kfTxt2">95157</p>
          <p class="kfTxt1 kfTxt3">公司邮箱</p>
          <p class="kfTxt2">jindoudou@jdd966.com</p>
          <div class="rigthArr"></div>
        </div>
      </div>
    </div>
  </el-row>
</template>

<script>
export default {
  name: "tab",
  props: {},
  data() {
    return {
      isShow: true,
      img: {
        logo: "./images/logo.png",
        fkFix:
          "./images/fkFix.png",
      },
      isShowTabs: false,
      isShowToTop: false,
      topViewHeight: 62, //topView的高度
    };
  },
  mounted() {
    console.log(this.$router.currentRoute);
    var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
    if (isMac) {
      this.isShow = false;
    }
    this.addScrollListener();
    this.topViewHeightInit();
  },
  methods: {
    topViewHeightInit() {
      window.addEventListener("resize", () => {
        this.topViewHeightReset();
      });
      this.topViewHeightReset();
    },
    topViewHeightReset() {
      this.topViewHeight =
        document.getElementsByClassName("topView")[0].offsetHeight;
    },
    addScrollListener() {
      window.addEventListener("scroll", () => {
        const window_scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        this.isShowToTop = window_scrollTop >= window.screen.height;
      });
    },
    backTop() {
      window.scroll(0, 0);
    },
    menuClick() {
      this.isShowTabs = !this.isShowTabs;
    },
    toqq() {
      if (Math.random() > 0.5) {
        window.open("tencent://message/?uin=3607907547");
      } else {
        window.open("tencent://message/?uin=3073534076");
      }
    },
    toPush(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style scoped lang="scss">
.topViewWrap {
  height: 62px;
}
.topView {
  width: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  background-color: #fff;
  box-shadow: 0rem 0.05rem 0.2rem 0rem rgba(0, 0, 0, 0.1);
  .logo {
    width: 180px;
    height: 58px;
    margin-left: 8%;
    margin-right: 8%;
    cursor: pointer;
    flex-shrink: 1;
  }
  .tabs {
    margin-right: 10%;
    flex-shrink: 0;
    .tabItem {
      cursor: pointer;
      margin-right: 38px;
      font-size: 16px;
      color: #333333;
      line-height: 58px;
      height: 58px;
      text-decoration: none;
      border-bottom: 4px solid transparent;
    }
    .router-link-active {
      border-bottom-color:#ff8800;
    }
  }
  .menu,
  .close {
    display: none;
  }
}
.kfBig {
  z-index: 100;
  width: 2.15rem;
  position: fixed;
  bottom: 25%;
  right: 1rem;
  transform: scale(0.66);
}
.toTop {
  width: 100%;
  height: 2.15rem;
  border-radius: 50%;
  width: 2.15rem;
  background: #f8fafd;
  box-shadow: 0rem 0.18rem 0.48rem 0rem rgba(153, 153, 153, 0.31);
  border: 0.13rem solid #ffffff;
  margin-bottom: 0.5rem;
  box-sizing: border-box;
  opacity: 0;
  cursor: pointer;
  .toTopImg {
    width: 1.1rem;
    height: 0.6rem;
  }
}
.toTop.show {
  opacity: 1;
}
.kfFix {
  cursor: pointer;
  opacity: 1;
  height: 6.73rem;
  background: #f8fafd;
  box-shadow: 0px 7px 19px 0px rgba(153, 153, 153, 0.31);
  border-radius: 1.38rem;
  border: 5px solid #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  &:hover .kfDes {
    right: 3rem;
    opacity: 1;
  }
  .fkFixImg {
    width: 1.08rem;
    margin-bottom: 0.23rem;
  }
  .fkTxt {
    width: 0.6rem;
    height: 3.6rem;
    font-size: 0.6rem;
    color: #333333;
    line-height: 0.9rem;
  }
  .kfDes {
    opacity: 0;
    position: absolute;
    transition: all 0.4s;
    right: 0;
    width: 6.6rem;
    height: 4.5rem;
    background: #f8fafd;
    box-sizing: border-box;
    padding: 0.55rem 0.5rem;
    box-shadow: 0px 7px 19px 0px rgba(153, 153, 153, 0.31);
    .kfTxt1 {
      font-size: 0.5rem;
      color: #333333;
      line-height: 0.75rem;
    }
    .kfTxt2 {
      font-size: 0.45rem;
      color: #666666;
      line-height: 0.75rem;
    }
    .kfTxt3 {
      margin-top: 0.4rem;
    }
    .rigthArr {
      position: absolute;
      right: -0.25rem;
      top: 45%;
      width: 0;
      height: 0;
      border-top: 0.25rem solid transparent;
      border-left: 0.25rem solid #f8fafd;
      border-bottom: 0.25rem solid transparent;
    }
  }
}
@media screen and (max-width: 1200px) {
  .topViewWrap {
    height: 58px;
  }
  .kfBig {
    display: none;
  }
  .topView {
    padding: 15px 0;
    box-shadow: none;
    .tabs {
      display: none;
    }
    .mobile {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 1000;
      top: 58px;
      width: 100%;
      margin: 0;
      background-color: #fff;
      display: block;
      text-align: center;
      overflow-y: auto;
      .tabItem {
        line-height: 110px;
        margin: 0;
        height: auto;
        font-size: 36px;
        border-bottom: 1px solid #e2e3e4;
      }
      .act {
        border: 0;
        border-bottom: 1px solid #e2e3e4;
      }
    }
    .logo {
      margin-left: 0;
      vertical-align: top;
      width: 248px;
      height: 80px;
    }
    .menu,
    .close {
      width: 36px;
      height: 36px;
      background: url(@/assets/menu.png) center center/cover no-repeat;
      display: block;
      margin-right: 30px;
    }
    .close {
      background-image: url(@/assets/closeIcon.png);
    }
  }
}
</style>

